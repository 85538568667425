import React from 'react';
import styled from 'styled-components';
import { Dispatch } from 'src/contexts/actions';
import { colors } from 'styles/styles';

type MenstruationInfoBoxTexts = { nextPeriodStartOn: string; remainedDates: string };
type Props = { dispatch: Dispatch; menstInfoBoxTexts: MenstruationInfoBoxTexts };

const StyledComponent = styled.div`
  .menst-mode-info-box-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: calc(100% - 185px);
  }

  .menst-mode-info-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 68px;
    width: 100%;
  }

  /* -- top layer */

  .menst-mode-info-box-top-layer {
    display: flex;
    justify-content: center;
    height: 20px;
  }

  .info-box-top-layer-text {
    color: ${colors.charcoalGrey};
    font-weight: 500;
    font-size: 11px;
    line-height: 1;
  }

  /* -- middle layer */

  .menst-mode-info-box-middle-layer {
    display: flex;
    justify-content: center;
    height: 26px;
    width: 100%;
    margin: 2px 0;
  }

  .count-down-text {
    line-height: 1;
    color: white;
    font-family: 'Noto_Sans_CJK_JP', sans-serif;
  }

  .count-down-text-number-size {
    font-size: 12px;
  }
  .count-down-text-string-size {
    font-size: 10px;
  }

  .menst-mode-info-box-date-zone {
    display: flex;
    height: 26px;
    align-items: center;
  }
  .menst-mode-info-box-date-text {
    letter-spacing: -0.1rem;
    line-height: 1;
    color: ${colors.darkBlack};
    font-size: 26px;
    font-family: 'sofia-pro', sans-serif;
    font-weight: 600;
  }
  .menst-mode-info-box-count-down-zone-wrapper {
    margin-left: 6px;
    padding: 5px 0 1px;
  }
  .menst-mode-info-box-count-down-zone {
    display: flex;
    box-sizing: border-box;
    padding: 4px 6px 0;
    justify-content: center;
    height: 20px;
    border-radius: 11px;
    background-color: ${colors.forestGreen};
  }
  .menst-mode-info-box-count-down-text {
    display: flex;
    align-items: flex-end;
    height: 12px;
  }

  /* -- bottom layer */
  .current-pill {
    display: flex;
    padding: 7px 0 3px;
  }

  .info-box-bottom-layer-text {
    line-height: 1;
    font-size: 10px;
    font-family: 'Noto_Sans_CJK_JP', sans-serif;
  }

  .info-box-bottom-layer-text-decoration {
    color: ${colors.charcoalGrey};
    white-space: nowrap;
  }

  .info-box-bottom-layer-pill-text-decoration {
    color: ${colors.brown};
    text-decoration: underline;
    white-space: nowrap;
  }

  .icon-description-zone {
    width: 61px;
    padding: 7px 0 3px;
  }

  .menst-mode-info-box-bottom-layer {
    display: flex;
    justify-content: center;
    height: 20px;
  }

  .question-circle-zone {
    display: flex;
    align-items: flex-end;
    width: 16px;
  }

  .question-circle {
    height: 16px;
    width: 16px;
  }
`;

export const MenstruationInfoBox = (props: Props): JSX.Element => (
  <StyledComponent>
    <div className="menst-mode-info-box">
      <div className="menst-mode-info-box-top-layer">
        <div className="info-box-top-layer-text">生理予定日</div>
      </div>

      <div className="menst-mode-info-box-middle-layer">
        <div className="menst-mode-info-box-date-zone">
          <div className="menst-mode-info-box-date-text">
            {props.menstInfoBoxTexts.nextPeriodStartOn}
          </div>
        </div>
        <div className="menst-mode-info-box-count-down-zone-wrapper">
          <div className="menst-mode-info-box-count-down-zone">
            <div className="menst-mode-info-box-count-down-text">
              <div className="count-down-text count-down-text-string-size">あと</div>
              <div className="count-down-text count-down-text-number-size">
                {props.menstInfoBoxTexts.remainedDates}
              </div>
              <div className="count-down-text count-down-text-string-size">日</div>
            </div>
          </div>
        </div>
      </div>

      <div className="menst-mode-info-box-bottom-layer">
        <div className="question-circle-zone">
          <img className="question-circle" alt="question mark" src="/question_circle.png" />
        </div>
        <div className="icon-description-zone">
          <div
            className="info-box-bottom-layer-text info-box-bottom-layer-text-decoration"
            onClick={() =>
              props.dispatch({ type: 'handleCalendarIconDescriptionBottomSheet', payload: true })
            }
          >
            アイコン説明
          </div>
        </div>
      </div>
    </div>
  </StyledComponent>
);
