import Router from 'next/router';
import React from 'react';
import styled from 'styled-components';
import { useActionProvider } from 'src/contexts/actions';
import { useNote } from 'src/hooks/useNote';
import { getToday } from 'src/lib/calendar';

const StyledButtons = styled.div<{ isPillMode: boolean }>`
  .calendar-float {
    display: flex;
    justify-content: center;
  }
  .button-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: fixed;
    top: calc(100% - 100px);
  }
  .button {
    border-radius: 33px;
    background-color: white;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.14);
    box-sizing: border-box;
  }

  .button.pill_intake {
    width: 120px;
    @media (max-width: 374px) {
      width: 93px;
    }
    height: 50px;
    margin-right: 8px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .button.edit_menst_calendar {
    width: ${(props) => (props.isPillMode ? '215px' : '226px')};
    @media (max-width: 374px) {
      width: ${(props) => (props.isPillMode ? '187px' : '203px')};
    }
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    flex: none;
    order: 1;
    flex-grow: 1;
  }

  .intake_icon {
    height: 30px;
    width: 30px;
  }

  .intake_cancel_icon {
    height: 30px;
    width: 30px;
  }

  .edit_menst_calendar_icon {
    height: 30px;
    width: 30px;
  }

  .text {
    font-size: 14px;
    @media (max-width: 374px) {
      font-size: 12px;
    }
    line-height: 100%;
    font-style: normal;
    font-family: 'Noto_Sans_JP_Bold', sans-serif;
    text-align: center;
    letter-spacing: 0.04em;
    font-feature-settings: 'pkna' on;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 4px;
  }
`;

const CalendarFloatButtonsDom = (props: {
  isPillMode: boolean;
  hasIntakedPillToday: boolean;
  handleRadio: () => void;
}): JSX.Element => (
  <StyledButtons {...props}>
    <div className="calendar-float">
      <div className="button-group">
        {props.isPillMode && (
          <>
            {props.hasIntakedPillToday ? (
              <div className="button pill_intake cancel" onClick={() => props.handleRadio()}>
                <img
                  src="/calendar/submit_intake_cancel.png"
                  alt=""
                  className="intake_cancel_icon"
                />
                <span className="text cancel">服薬取消</span>
              </div>
            ) : (
              <div className="button pill_intake intake" onClick={() => props.handleRadio()}>
                <img src="/calendar/submit_intake.png" alt="" className="intake_icon" />
                <span className="text">飲んだ</span>
              </div>
            )}
          </>
        )}
        <div className="button edit_menst_calendar" onClick={() => Router.push('/menstruation')}>
          <img
            src="/calendar/edit_menstruation_calendar.png"
            alt=""
            className="edit_menst_calendar_icon"
          />
          <span className="text">生理日を記録・編集する</span>
        </div>
      </div>
    </div>
  </StyledButtons>
);

type ContainerProps = { isPillMode: boolean };

export const CalendarFloatButtons = (props: ContainerProps): JSX.Element => {
  const {
    state: { femappUser },
  } = useActionProvider();
  const [note, { upsertNote }] = useNote(getToday());
  const handleRadio = () => upsertNote('pillIntake', note?.pillIntake ? null : 'INTAKED');

  return (
    <>
      {femappUser && (
        <CalendarFloatButtonsDom
          {...{
            isPillMode: props.isPillMode,
            hasIntakedPillToday: Boolean(note?.pillIntake),
            handleRadio,
          }}
        />
      )}
    </>
  );
};
