import styled from 'styled-components';
import { DateTileProps } from './types';
import { allCalendarIconInfo } from 'src/lib/calendarIcons';
import { colors } from 'styles/styles';

export const DateTile = (props: DateTileProps): JSX.Element => {
  return (
    <StyledDateTile>
      <div className="date_tile">
        {props.deco && (
          <div className="date_deco_wrapper">
            <img
              src={props.deco.path}
              alt={props.deco.alt}
              className={props.isToday ? 'today_deco' : 'not_today_deco'}
            />
          </div>
        )}
        <div className="top_icon_and_number">
          {props.topIcon && (
            <img src={props.topIcon.path} className="icon" alt={props.topIcon.alt} />
          )}
          <div
            className={
              'number' +
              (props.topIcon ? ' with_top_icon' : ' without_top_icon') +
              (props.isToday ? ' today_number' : '')
            }
          >
            {props.date.getDate()}
          </div>
        </div>
        <div className={props.deco ? 'bottom_icons with_deco' : 'bottom_icons without_deco'}>
          {props.isIncludedIntakeDates && (
            <img className="bottom_icon" src={allCalendarIconInfo.INTAKE.path} alt="intake date" />
          )}
          {props.isIncludedDatesWithNote && (
            <img className="bottom_icon" src={allCalendarIconInfo.NOTE.path} alt="date with note" />
          )}
        </div>
      </div>
    </StyledDateTile>
  );
};

const StyledDateTile = styled.div`
  padding-top: 12px;
  .date_tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 56px;
    padding-top: 4px;
    box-sizing: border-box;
    position: relative;
  }
  .date_deco_wrapper {
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .today_deco {
    width: 28px;
    height: 28px;
  }
  .not_today_deco {
    width: 24px;
    height: 24px;
  }
  .top_icon_and_number {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 28px;
    height: 28px;
    position: absolute;
  }
  .icon {
    width: 16px;
    height: 16px;
    margin-top: -9px;
  }
  .number {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 15px;
    font-size: 15px;
    line-height: 1;
    font-family: 'sofia-pro', sans-serif;
  }
  .number.without_top_icon {
    margin-top: 7px;
  }
  .number.with_top_icon {
    margin-top: 0;
  }
  .today_number {
    color: ${colors.brown};
  }
  .bottom_icons {
    display: flex;
    flex-direction: row;
  }
  .bottom_icons.without_deco {
    margin-top: 30px;
  }
  .bottom_icons.with_deco {
    margin-top: 2px;
  }
  .bottom_icon {
    height: 8px;
    width: 8px;
    margin: 0 1px;
  }
`;
