import { css } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

export const baseToasterStyle = css`
  .Toastify__toast {
    min-height: 50px;
    width: 100%;
    text-align: center;
  }
  .Toastify__toast-body > div {
    font-family: 'Noto_Sans_JP_Bold', sans-serif;
    font-size: 12px;
  }

  /** custom slide animation **/
  @keyframes slideInDown {
    from {
      transform: translate3d(0, -100%, 0);
      visibility: visible;
    }
    to {
      @include transform;
    }
  }
  @keyframes slideOutUp {
    from {
      @include transform;
    }
    to {
      visibility: hidden;
      transform: translate3d(0, -500px, 0);
    }
  }
  .custom-slide-in {
    animation: slideInDown 0.4s ease-out both;
  }
  .custom-slide-out {
    animation: slideOutUp 1.25s ease-in both;
  }
`;
