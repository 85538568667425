import React, { useEffect } from 'react';
import { cssTransition, toast, ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import { useActionProvider, CalendarPageToastProps } from 'src/contexts/actions';
import { colors } from 'styles/styles';
import { baseToasterStyle } from 'styles/toaster';
import 'react-toastify/dist/ReactToastify.css';

const StyledToaster = styled(ToastContainer)<{ color: string }>`
  ${baseToasterStyle}
  .Toastify__toast {
    background: ${(props) => props.color};
  }
`;

const customSlide = cssTransition({
  enter: 'custom-slide-in',
  exit: 'custom-slide-out',
});

const calendarPageToasts: CalendarPageToastProps[] = [
  {
    name: 'stoppingPillIntake',
    text: 'ピル服薬をオフにしました',
    color: colors.skyBlue,
  },
];

export const PageToastCalendar = (): JSX.Element => {
  const {
    state: { calendarPageToast },
    dispatch,
  } = useActionProvider();
  const pageToast = calendarPageToasts.find((t) => t.name === calendarPageToast);
  const autoCloseMilliSec = 3000;

  useEffect(() => {
    if (pageToast) {
      const notify = () =>
        toast(pageToast.text, {
          autoClose: autoCloseMilliSec,
          closeButton: false,
          draggable: false,
          draggableDirection: 'y',
          draggablePercent: 200,
          hideProgressBar: true,
          position: 'top-center',
          transition: customSlide,
        });

      notify();
      setTimeout(() => {
        dispatch({ type: 'setCalendarPageToast', payload: undefined });
      }, autoCloseMilliSec + 1000); // トースターが消えるアニメーションがあるので、0.1秒のバッファをもたせる
    }
  }, [dispatch, pageToast]);

  return pageToast ? <StyledToaster color={pageToast.color} /> : <></>;
};
