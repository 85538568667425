import styled from 'styled-components';
import { colors } from 'styles/styles';

export const StyledHiddenCalendarHeader = styled.div`
  .hidden-calendar {
    position: fixed;
    right: 0;
    width: 32px;
    background-color: ${colors.cream};
    border-radius: 8px 0 0 8px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.14);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .menst-mode-hidden-calendar-height {
    height: 97px;
  }
  .pill-mode-hidden-calendar-height {
    height: 120px;
  }
  .date-drop-zone {
    height: 30px;
    width: 100%;
    padding: 6px 5px 3px 6px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .date-drop {
    height: 25px;
    width: 18px;
  }
  .month {
    position: absolute;
    font-size: 10px;
    top: 6px;
    right: 17px;
    font-family: 'Avenir Next', sans-serif;
    font-weight: 700;
    display: flex;
  }
  .slash {
    position: absolute;
    top: 7px;
    left: 14px;
    font-size: 16px;
    font-family: 'Avenir Next', sans-serif;
    font-weight: 400;
    transform: rotate(23.46deg);
  }
  .day {
    position: absolute;
    top: 17px;
    left: 18px;
    font-size: 10px;
    font-family: 'Avenir Next', sans-serif;
    font-weight: 700;
  }
  .pill-index-zone {
    height: 30px;
    width: 100%;
    padding: 7px 4px 3px 6px;
    box-sizing: border-box;
    margin: 10px 0;
  }
  .pill-index-circle {
    box-sizing: border-box;
    padding-top: 6px;
    display: flex;
    justify-content: center;
    height: 20px;
    width: 20px;
    background-color: white;
    border-radius: 50%;
  }
  .pill-index {
    font-size: 12px;
    line-height: 1;
    color: ${colors.darkBeige};
    font-family: 'Avenir Next', sans-serif;
  }

  .menst-mode-notification-zone {
    box-sizing: border-box;
    width: 100%;
    padding-left: 6px;
    margin-top: 14px;
  }
  .pill-mode-notification-zone {
    box-sizing: border-box;
    height: 30px;
    width: 100%;
    padding-top: 3px;
    padding-left: 6px;
  }
  .notified-mail {
    width: 22px;
    height: 22px;
  }
`;
