import React, { useMemo } from 'react';
import { MenstruationMode } from './MenstruationMode';
import { PillMode } from './PillMode';
import { getPillInfoBoxTexts } from './utils';
import { useActionProvider } from 'src/contexts/actions';
import { Cycle } from 'src/generated/graphql';
import { useCurrentPillIntake } from 'src/hooks/useCurrentPillIntake';
import { useHeaderMenstrationInfo } from 'src/hooks/useHeaderMenstrationInfo';

type ContainerProps = {
  isPillMode: boolean;
  opening: boolean;
  cycles: Cycle[];
};

export const CalendarHeaderHidden = (props: ContainerProps): JSX.Element => {
  const { dispatch } = useActionProvider();
  const [currentPillIntake] = useCurrentPillIntake();
  const { hidden } = useHeaderMenstrationInfo();

  const pillInfoBoxTexts = useMemo(() => {
    return getPillInfoBoxTexts(currentPillIntake);
  }, [currentPillIntake]);

  const monthText = hidden?.monthText;
  const dateText = hidden?.dateText;

  return props.isPillMode ? (
    <PillMode
      {...{
        monthText,
        dateText,
        opening: props.opening,
        dispatch,
        drawalDate: pillInfoBoxTexts.drawalDate,
        tabletNumber: pillInfoBoxTexts.tabletNumber,
        isAnyDrugPeriod:
          pillInfoBoxTexts.isActiveDrugPeriod || pillInfoBoxTexts.isPlaceboDrugPeriod,
      }}
    />
  ) : (
    <MenstruationMode {...{ monthText, dateText, opening: props.opening, dispatch }} />
  );
};
