import { motion } from 'framer-motion';
import { StyledHiddenCalendarHeader } from './StyledHiddenCalendarHeader';
import { Dispatch } from 'src/contexts/actions';

type Props = {
  dateText: string;
  dispatch: Dispatch;
  drawalDate: string;
  isAnyDrugPeriod: boolean;
  monthText: string;
  opening: boolean;
  tabletNumber: string;
};

export const PillMode = (props: Props): JSX.Element => (
  <StyledHiddenCalendarHeader>
    <motion.div
      className="hidden-calendar pill-mode-hidden-calendar-height"
      animate={{
        opacity: props.opening ? 0 : 1,
        x: props.opening ? -window.innerWidth : 0,
      }}
      transition={{ duration: 0.3 }}
      onClick={() => props.dispatch({ type: 'handleCalendarHeader', payload: !props.opening })}
    >
      <div className="date-drop-zone">
        <img className="date-drop" alt="" src="/white_drop.png" />
        <span className="month">{props.monthText}</span>
        <span className="slash">/</span>
        <span className="day">{props.dateText}</span>
      </div>
      <div className="pill-index-zone">
        <div className="pill-index-circle">
          <span className="pill-index">
            {props.isAnyDrugPeriod ? props.tabletNumber : props.drawalDate}
          </span>
        </div>
      </div>
    </motion.div>
  </StyledHiddenCalendarHeader>
);
