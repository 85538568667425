import { Note, Cycle, Pill } from 'src/generated/graphql';
import { getToday, isIncludedDate, isToday } from 'src/lib/calendar';
import { calendarDecorationInfo } from 'src/lib/calendarDecoration';
import { allCalendarIconInfo, CalendarIconInfo } from 'src/lib/calendarIcons';
import { addDays } from 'src/lib/dateFormatter';
import { getBetweenDates } from 'src/lib/dateFormatter';
import { pillCycleLength } from 'src/lib/pillIntakes';

/*
 * 引数で与えられた症状記録のリストから、症状記録をもつ日付を返す。
 */
export const notesToDatesWithNote = (notes: Note[]): Date[] => {
  return notes.map((note) => new Date(note.date));
};

/*
 * 引数で与えられた症状記録のリストから、症状記録をもつ日付を返す。
 * ただし、症状記録の内容が服薬記録のみの日付は、返さない。
 */
export const notesToDatesWithNoteWithNotOnlyPillIntake = (notes: Note[]): Date[] => {
  const excluded_keys = ['__typename', 'id', 'date', 'pillIntake'];
  const notesWithNotOnlyPillIntake = notes.filter((note) =>
    Object.entries(note).some(
      (e) => !excluded_keys.includes(e[0]) && e[1] !== null && Boolean(e[1].length),
    ),
  );
  return notesWithNotOnlyPillIntake.map((note) => new Date(note.date));
};

export const cyclesToMenstDates = (cycles: Cycle[]): Date[] => {
  const dates: Date[] = [];
  cycles.map((cycle) => {
    if (cycle.id) {
      const startDate = new Date(cycle.periodStartOn);
      const endDate = addDays(startDate, cycle.periodLength - 1);
      dates.push(...getBetweenDates(startDate, endDate));
    }
  });
  return dates;
};

export const cyclesToPredictedMenstDates = (cycles: Cycle[]): Date[] => {
  const dates: Date[] = [];
  cycles.map((cycle) => {
    if (!cycle.id) {
      const startDate = new Date(cycle.periodStartOn);
      const endDate = addDays(startDate, cycle.periodLength - 1);
      dates.push(...getBetweenDates(startDate, endDate));
    }
  });
  return dates;
};

export const cyclesToOvulationDates = (cycles: Cycle[]): Date[] => {
  return cycles.map((cycle) => new Date(cycle.ovulationScheduledOn));
};

export const cyclesToPmsDates = (cycles: Cycle[]): Date[] => {
  const dates: Date[] = [];
  cycles.map((cycle) => {
    dates.push(...getBetweenDates(new Date(cycle.pmsStartOn), new Date(cycle.pmsEndOn)));
  });
  return dates;
};

/*
 * ピルモードの場合の、今日から1年後までの、出血予定日のリストを返す。
 *
 * ロジックは下記の通り。
 * - 出血開始日: 休薬偽薬開始日 + 2
 * - 出血終了日: 休薬偽薬終了日
 */
export const sheetStartDateToPredictedMenstruationDates = (
  pill: Pill,
  sheetStartDate: Date,
): Date[] => {
  const dates: Date[] = [];
  const nextYearToday = getToday();
  nextYearToday.setFullYear(nextYearToday.getFullYear() + 1);
  const targetDate = new Date(sheetStartDate.getTime());
  while (targetDate < nextYearToday) {
    targetDate.setDate(targetDate.getDate() + pillCycleLength);
    const endDate = new Date(targetDate.getTime());
    const startDate = new Date(endDate.getTime());
    startDate.setDate(startDate.getDate() - (pillCycleLength - pill.activeDrugSize) + 2);
    endDate.setDate(endDate.getDate() - 1);
    dates.push(...getBetweenDates(startDate, endDate));
  }
  const today = getToday();
  return dates.filter((date) => date >= today); // 今日以降だけ
};

export const notesToPillIntakeDates = (notes: Note[]): Date[] => {
  return notes.filter((note) => note.pillIntake).map((note) => new Date(note.date));
};

export const dateDecoration = (
  date: Date,
  drawalOrPlaceboDates: Date[],
  predictedMenstDates: Date[],
): { path: string; alt: string } | undefined => {
  const isDrawal = (date: Date): boolean => isIncludedDate(date, drawalOrPlaceboDates);
  const isPredictedMenst = (date: Date): boolean => isIncludedDate(date, predictedMenstDates);
  if (isToday(date) && isDrawal(date) && isPredictedMenst(date)) {
    return calendarDecorationInfo.TODAY_DRAWAL_PREDICTED_MENST;
  }
  if (isToday(date) && isDrawal(date)) {
    return calendarDecorationInfo.TODAY_DRAWAL;
  }
  if (isToday(date) && isPredictedMenst(date)) {
    return calendarDecorationInfo.TODAY_PREDICTED_MENST;
  }
  if (isToday(date)) {
    return calendarDecorationInfo.TODAY;
  }
  if (isDrawal(date) && isPredictedMenst(date)) {
    return calendarDecorationInfo.DRAWAL_PREDICTED_MENST;
  }
  if (isPredictedMenst(date)) {
    return calendarDecorationInfo.PREDICTED_MENST;
  }
  if (isDrawal(date)) {
    return calendarDecorationInfo.DRAWAL;
  }
  return undefined;
};

export const getTopIcon = (
  date: Date,
  menstDates: Date[],
  predictedMenstDates: Date[],
  ovulationDates: Date[],
  pmsDates: Date[],
): CalendarIconInfo | undefined => {
  if (isIncludedDate(date, menstDates)) {
    return allCalendarIconInfo.MENST;
  } else if (isIncludedDate(date, predictedMenstDates)) {
    return allCalendarIconInfo.PREDICTED_MENST;
  } else if (isIncludedDate(date, ovulationDates)) {
    return allCalendarIconInfo.OVULATION;
  } else if (isIncludedDate(date, pmsDates)) {
    return allCalendarIconInfo.PMS;
  } else {
    return undefined;
  }
};
