import { StoreObject, Reference } from '@apollo/client/utilities/graphql/storeUtils';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useActionProvider } from 'src/contexts/actions';
import {
  Note,
  useCreateNoteMutation,
  useDeleteNoteMutation,
  useUpdateNoteMutation,
  UserNoteDocument,
} from 'src/generated/graphql';
import { isNoteEditedByUser } from 'src/lib/calendar';
import { formatDateToStr } from 'src/lib/dateFormatter';

type UpsertNote = (key: string, value: string | number | { name: string }[] | null) => void;
type DeleteNote = (shouldBack: boolean) => void;
type Mutations = { upsertNote: UpsertNote; deleteNote: DeleteNote };
type ReturnValues = [Note, Mutations];

/*
 * Noteのmutationに使用する
 *
 * 返却するのは以下の3つ
 * - 引数で指定された日付のNoteオブジェクト
 * - upsert関数 (upsert後に、実質的な空レコードになったら、deleteまで実行する)
 * - delete関数
 */
export const useNote = (targetDate: Date): ReturnValues => {
  const router = useRouter();
  const [note, setNote] = useState<Note>();
  const [createNoteMutation] = useCreateNoteMutation();
  const [updateNoteMutation] = useUpdateNoteMutation();
  const [deleteNoteMutation] = useDeleteNoteMutation({
    update(cache, { data }) {
      if (!femappUser) return;
      cache.modify({
        id: cache.identify(femappUser),
        fields: {
          notes(existingNoteRefs, { readField }) {
            return existingNoteRefs.filter(
              (noteRef: StoreObject | Reference | undefined) =>
                data?.femappDeleteNote?.id !== readField('id', noteRef),
            );
          },
        },
      });
    },
  });
  const {
    state: { femappUser, femappUserNotes },
  } = useActionProvider();

  const refetchQueries = [
    {
      query: UserNoteDocument,
      variables: {
        noteDateFrom: formatDateToStr(new Date(targetDate)),
        noteDateTo: formatDateToStr(new Date(targetDate)),
      },
    },
  ];

  const upsertNote: UpsertNote = (key, value) => {
    if (!note?.id) {
      createNoteMutation({
        variables: {
          noteAttributes: {
            date: new Date(targetDate),
            [key]: value,
          },
        },
        refetchQueries: refetchQueries,
        awaitRefetchQueries: false,
      }).then(({ errors }) => {
        if (errors) {
          console.log(errors);
          if (errors[0].extensions?.appCode === 'U00001') {
            alert(errors[0].message);
          } else {
            throw new Error('Could not create note.');
          }
        }
      });
    } else {
      updateNoteMutation({
        variables: {
          id: note.id,
          noteAttributes: {
            [key]: value,
          },
        },
      }).then(({ errors }) => {
        if (errors) {
          if (errors[0].extensions?.appCode === 'U00001') {
            alert(errors[0].message);
          } else {
            throw new Error('Could not update note.');
          }
        }
      });
    }
  };

  const deleteNote = useCallback<DeleteNote>(
    (shouldBack) => {
      if (note?.id) {
        deleteNoteMutation({
          variables: {
            id: note.id,
          },
        }).then(({ errors }) => {
          if (errors) {
            if (errors[0].extensions?.appCode === 'U00001') {
              alert(errors[0].message);
            } else {
              throw new Error('Note could not delete.');
            }
          }
          shouldBack && router.back();
        });
      }
    },
    [deleteNoteMutation, note?.id, router],
  );

  useEffect(() => {
    if (!femappUserNotes || !targetDate) return;
    const tempNote = femappUserNotes.find((n) => n.date === formatDateToStr(targetDate));
    if (tempNote && !isNoteEditedByUser(tempNote)) {
      deleteNote(false);
      return setNote(undefined); // note が not undefined から undefined に変化する場合があるので setNote(undefeind) を明示的に行う必要がある
    }
    setNote(tempNote);
  }, [femappUserNotes, targetDate, deleteNote]);

  return [note, { upsertNote, deleteNote }];
};
