import React from 'react';
import styled from 'styled-components';
import { MenstruationInfoBox } from './MenstruationInfoBox';
import { Dispatch, useActionProvider } from 'src/contexts/actions';

type MenstruationInfoBoxTexts = { nextPeriodStartOn: string; remainedDates: string };

type ContainerProps = { menstInfoBoxTexts: MenstruationInfoBoxTexts };
type Props = { dispatch: Dispatch } & ContainerProps;

const StyledHeader = styled.div`
  display: flex;
  width: 100%;
  margin-left: 16px;
  @media (max-width: 374px) {
    margin-left: 16px;
  }
  justify-content: center;

  .menst-mode-info-box-zone {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: calc(100% - 193.1px);
    @media (max-width: 374px) {
      width: calc(100% - 133.1px);
    }
  }
`;

const CalendarHeaderFullMenstruationModeDom = (props: Props): JSX.Element => (
  <StyledHeader>
    <div className="menst-mode-info-box-zone">
      <MenstruationInfoBox {...props} />
    </div>
  </StyledHeader>
);

export const CalendarHeaderFullMenstruationMode = (props: ContainerProps): JSX.Element => {
  const { dispatch } = useActionProvider();
  return (
    <CalendarHeaderFullMenstruationModeDom
      {...{ dispatch, menstInfoBoxTexts: props.menstInfoBoxTexts }}
    />
  );
};
