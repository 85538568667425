import { CurrentSheetAttributes } from 'src/hooks/useCurrentPillIntake';

type MonthDateText = {
  monthText: string;
  dateText: string;
};

export const defaultMonthDateText: MonthDateText = { monthText: '-', dateText: '-' };

type GetPillInfoBoxTexts = {
  tabletNumber: string | '-';
  drawalDate: string | '-';
  remainedActiveDrugDate?: string | '-';
  remainedSheetDate?: string | '-';
  isActiveDrugPeriod: boolean;
  isPlaceboDrugPeriod: boolean;
};

export const getPillInfoBoxTexts = (
  currentPillIntake: CurrentSheetAttributes,
): GetPillInfoBoxTexts => {
  return {
    tabletNumber: currentPillIntake?.tabletNumber?.toString() ?? '-',
    drawalDate: currentPillIntake?.drawalDays?.toString() ?? '-',
    remainedActiveDrugDate: currentPillIntake?.remainedActiveDrugDays?.toString() ?? '-',
    remainedSheetDate: currentPillIntake?.remainedSheetDays?.toString() ?? '-',
    isActiveDrugPeriod: currentPillIntake?.isActiveDrugPeriod ?? true,
    isPlaceboDrugPeriod: currentPillIntake?.isPlaceboDrugPeriod ?? false,
  };
};
