import { motion } from 'framer-motion';
import { StyledHiddenCalendarHeader } from './StyledHiddenCalendarHeader';
import { Dispatch } from 'src/contexts/actions';

type Props = {
  dateText: string;
  dispatch: Dispatch;
  opening: boolean;
  monthText: string;
};

export const MenstruationMode = (props: Props): JSX.Element => (
  <StyledHiddenCalendarHeader>
    <motion.div
      className="hidden-calendar menst-mode-hidden-calendar-height"
      animate={{
        opacity: props.opening ? 0 : 1,
        x: props.opening ? -window.innerWidth : 0,
      }}
      transition={{ duration: 0.3 }}
      onClick={() => props.dispatch({ type: 'handleCalendarHeader', payload: !props.opening })}
    >
      <div className="date-drop-zone">
        <img className="date-drop" alt="" src="/white_drop.png" />
        <span className="month">{props.monthText}</span>
        <span className="slash">/</span>
        <span className="day">{props.dateText}</span>
      </div>
    </motion.div>
  </StyledHiddenCalendarHeader>
);
