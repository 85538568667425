import Router from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';
import { default as ReactCalendar } from 'react-calendar';
import { DateTile } from './DateTile';
import { NavigationLabel } from './NavigationLabel';
import { DateTileProps } from './types';
import {
  cyclesToMenstDates,
  cyclesToOvulationDates,
  cyclesToPmsDates,
  cyclesToPredictedMenstDates,
  dateDecoration,
  getTopIcon,
  notesToDatesWithNote,
  notesToDatesWithNoteWithNotOnlyPillIntake,
  notesToPillIntakeDates,
  sheetStartDateToPredictedMenstruationDates,
} from './utils';
import { useActionProvider } from 'src/contexts/actions';
import { getToday, isDisableTile, isAfterToday, isToday, isIncludedDate } from 'src/lib/calendar';
import { formatDateToStr } from 'src/lib/dateFormatter';

type ReactCalendarWrapperProps = {
  activeStartDate: Date;
};

export const ReactCalendarWrapper = ({
  activeStartDate,
}: ReactCalendarWrapperProps): JSX.Element => {
  const { state, dispatch } = useActionProvider();
  const [intakeDates, setIntakeDates] = useState<Date[]>([]);
  const [datesWithNote, setDatesWithNote] = useState<Date[]>([]);
  const [datesWithNoteWithNotOnlyPillIntake, setDatesWithNoteNotOnlyPillIntake] = useState<Date[]>(
    [],
  );
  const [menstDates, setMenstDates] = useState<Date[]>([]);
  const [predictedMenstDates, setPredictedMenstDates] = useState<Date[]>([]);
  const [drawalOrPlaceboDates, setDrawalOrPlaceboDates] = useState<Date[]>([]);
  const [ovulationDates, setOvulationDates] = useState<Date[]>([]);
  const [pmsDates, setPmsDates] = useState<Date[]>([]);

  useEffect(() => {
    if (!state.femappUserNotes) return;
    setDatesWithNote(notesToDatesWithNote(state.femappUserNotes));
    setDatesWithNoteNotOnlyPillIntake(
      notesToDatesWithNoteWithNotOnlyPillIntake(state.femappUserNotes),
    );
  }, [state.femappUserNotes]);

  /*
   * 生理日と生理予定日を計算する
   */
  useEffect(() => {
    if (state.femappUser?.hasPillPlugin) {
      // ピルモードの場合
      //
      // ・生理日は自分で記録した日付が表示される
      // ・生理予定日は休薬偽薬期間と同じ日付が表示される
      if (!state.femappCycle?.cycles) return;
      const cycles = state.femappCycle.cycles;
      setMenstDates(cyclesToMenstDates(cycles).filter((date) => date <= getToday())); // 昨日以前で自分が生理日として記録した日
      if (!state.femappUser?.pill || !state.femappUser?.sheetStartDate) return;
      setPredictedMenstDates(
        sheetStartDateToPredictedMenstruationDates(
          state.femappUser.pill,
          new Date(state.femappUser.sheetStartDate),
        ),
      );
    } else {
      // 生理モードの場合
      if (!state.femappCycle?.cycles) return;
      const cycles = state.femappCycle.cycles;
      setMenstDates(cyclesToMenstDates(cycles));
      setPredictedMenstDates(cyclesToPredictedMenstDates(cycles));
    }
  }, [
    state.femappCycle?.cycles,
    state.femappUser?.pill,
    state.femappUser?.hasPillPlugin,
    state.femappUser?.sheetStartDate,
  ]);

  useEffect(() => {
    if (!state.femappUser?.pill || !state.femappUser?.sheetStartDate) return;
    setDrawalOrPlaceboDates(
      sheetStartDateToPredictedMenstruationDates(
        state.femappUser.pill,
        new Date(state.femappUser.sheetStartDate),
      ),
    );
  }, [state.femappUser?.pill, state.femappUser?.sheetStartDate]);

  useEffect(() => {
    if (!state.femappCycle?.cycles) return;
    const cycles = state.femappCycle.cycles;
    setOvulationDates(cyclesToOvulationDates(cycles));
    setPmsDates(cyclesToPmsDates(cycles));
  }, [state.femappCycle?.cycles]);

  useEffect(() => {
    if (!state.femappUserNotes) return;
    setIntakeDates(notesToPillIntakeDates(state.femappUserNotes));
  }, [state.femappUserNotes]);

  const TileContent = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ activeStartDate, date }) => {
      if (isDisableTile(activeStartDate, date)) return <div></div>;

      const deco: { path: string; alt: string } | undefined = dateDecoration(
        date,
        drawalOrPlaceboDates,
        predictedMenstDates,
      );
      const topIcon = getTopIcon(date, menstDates, predictedMenstDates, ovulationDates, pmsDates);

      const props: DateTileProps = {
        date,
        isToday: isToday(date),
        deco,
        topIcon,
        isIncludedIntakeDates: isIncludedDate(date, intakeDates),
        isIncludedDatesWithNote: isIncludedDate(date, datesWithNoteWithNotOnlyPillIntake),
      };

      return <DateTile {...props} />;
    },
    [
      datesWithNoteWithNotOnlyPillIntake,
      drawalOrPlaceboDates,
      intakeDates,
      menstDates,
      ovulationDates,
      pmsDates,
      predictedMenstDates,
    ],
  );

  return (
    <ReactCalendar
      activeStartDate={activeStartDate}
      calendarType={'US'}
      navigationLabel={NavigationLabel}
      tileContent={TileContent}
      tileDisabled={({ activeStartDate, date }) => isDisableTile(activeStartDate, date)}
      onClickDay={(date: Date) => {
        if (isAfterToday(date)) return;
        const withNote: boolean = datesWithNote
          .map((d) => formatDateToStr(d))
          .includes(formatDateToStr(date));
        if (withNote) {
          dispatch({ type: 'setSelectedDate', payload: date });
          dispatch({ type: 'handleNoteBottomSheet', payload: true });
        } else {
          Router.push({ pathname: '/note', query: { date: formatDateToStr(date) } });
        }
      }}
    />
  );
};
