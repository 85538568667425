import React from 'react';
import styled from 'styled-components';
import { PillModeIntakeInfoBox } from './PillModeIntakeInfoBox';
import { PillModeMenstruationInfoBox } from './PillModeMenstruationInfoBox';
import { Dispatch, useActionProvider } from 'src/contexts/actions';
import { PillInfoBoxTexts } from 'src/lib/calendar';

type MenstruationInfoBoxTexts = { nextPeriodStartOn: string; remainedDates: string };

type ContainerProps = {
  pillInfoBoxTexts: PillInfoBoxTexts;
  menstInfoBoxTexts: MenstruationInfoBoxTexts;
};

type Props = {
  dispatch: Dispatch;
  menstInfoBoxTexts: MenstruationInfoBoxTexts;
  pillInfoBoxTexts: PillInfoBoxTexts;
};

const StyledCalendarHeader = styled.div`
  display: flex;
  width: 100%;
  margin-left: 16px;
  @media (max-width: 374px) {
    margin-left: 8px;
  }

  .pill-mode-info-box-zone {
    display: flex;
    justify-content: center;
    height: 86px;
    margin: auto;
    @media (min-width: 768px) {
      width: 648px;
    }
  }

  .pill-mode-advice-box-zone {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const CalendarHeaderFullPillModeDom = (props: Props): JSX.Element => (
  <StyledCalendarHeader>
    <div className="pill-mode-info-box-zone">
      <PillModeMenstruationInfoBox
        {...{ dispatch: props.dispatch, menstInfoBoxTexts: props.menstInfoBoxTexts }}
      />
      <PillModeIntakeInfoBox {...{ pillInfoBoxTexts: props.pillInfoBoxTexts }} />
    </div>
  </StyledCalendarHeader>
);

export const CalendarHeaderFullPillMode = (props: ContainerProps): JSX.Element => {
  const { dispatch } = useActionProvider();
  return (
    <CalendarHeaderFullPillModeDom
      {...{
        dispatch,
        menstInfoBoxTexts: props.menstInfoBoxTexts,
        pillInfoBoxTexts: props.pillInfoBoxTexts,
      }}
    />
  );
};
