import { CalendarHeaderFull } from 'src/components/organisms/CalendarHeaderFull';
import { CalendarHeaderHidden } from 'src/components/organisms/CalendarHeaderHidden';
import { useActionProvider } from 'src/contexts/actions';

type ContainerProps = { isPillMode: boolean };

export const CalendarHeader = (props: ContainerProps): JSX.Element => {
  const { state } = useActionProvider();
  const opening = state.openCalendarHeader;
  const containerProps = {
    isPillMode: props.isPillMode,
    opening,
    cycles: state.femappCycle?.cycles ?? [],
  };
  return (
    <div style={{ position: 'absolute', top: 16 }}>
      <CalendarHeaderHidden {...containerProps} />
      <CalendarHeaderFull {...containerProps} />
    </div>
  );
};
