import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useActionProvider, Dispatch } from 'src/contexts/actions';
import { clipText } from 'src/lib/textFormatter';
import { colors } from 'styles/styles';

type ContainerProps = {
  pillInfoBoxTexts: {
    tabletNumber: string;
    drawalDate: string;
    remainedActiveDrugDate: string;
    remainedSheetDate: string;
    isActiveDrugPeriod: boolean;
    isPlaceboDrugPeriod: boolean;
  };
};

type Props = {
  dispatch: Dispatch;
  isAnyDrugPeriod: boolean;
  pillName: string;
} & ContainerProps;

const StyledInfoBox = styled.div`
  .pill-mode-info-box {
    height: 86px;
    width: 105px;
  }

  .pill-mode-info-box-top-layer {
    box-sizing: border-box;
    display: flex;
    padding-top: 1;
    height: 16px;
    width: 100%;
    justify-content: center;
  }

  /* -- top layer */

  .info-box-top-layer-text {
    color: ${colors.charcoalGrey};
    font-weight: 500;
    font-size: 11px;
    line-height: 1;
  }

  /* -- middle layer */

  .pill-mode-info-box-middle-layer {
    height: 50px;
    width: 100%;
  }

  .count-down-text {
    line-height: 1;
    color: white;
    font-family: 'Noto_Sans_CJK_JP', sans-serif;
  }

  .count-down-text-number-size {
    font-size: 12px;
  }
  .count-down-text-string-size {
    font-size: 10px;
  }

  .whitespace-nowrap {
    white-space: nowrap;
  }
  .pill-mode-info-box-middle-top-layer {
    display: flex;
    justify-content: center;
  }
  .pill-mode-date-text {
    line-height: 1;
    color: ${colors.darkBlack};
    font-size: 26px;
    font-family: 'sofia-pro', sans-serif;
    font-weight: 600;
  }
  .pill-mode-pill-text-zone {
    display: flex;
    align-items: flex-end;
  }
  .pill-mode-pill-number-text {
    line-height: 1;
    color: ${colors.darkBlack};
    font-size: 26px;
    font-family: 'sofia-pro', sans-serif;
    font-weight: 600;
  }
  .pill-mode-pill-string-text {
    line-height: 1;
    font-size: 14px;
    font-family: 'Noto_Sans_CJK_JP', sans-serif;
    font-weight: 400;
  }
  .pill-mode-info-box-middle-bottom-layer {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 24px;
  }
  .pill-mode-info-box-count-down-zone {
    display: flex;
    box-sizing: border-box;
    padding: 4px 6px 0;
    justify-content: center;
    height: 20px;
    border-radius: 11px;
    background-color: ${colors.forestGreen};
  }
  .pill-mode-info-box-count-down-text-zone {
    display: flex;
    align-items: flex-end;
    height: 12px;
  }

  /* -- bottom layer */
  .current-pill {
    display: flex;
    padding: 7px 0 3px;
  }

  .info-box-bottom-layer-text {
    line-height: 1;
    font-size: 10px;
    font-family: 'Noto_Sans_CJK_JP', sans-serif;
  }

  .info-box-bottom-layer-text-decoration {
    color: ${colors.charcoalGrey};
    white-space: nowrap;
  }

  .info-box-bottom-layer-pill-text-decoration {
    color: ${colors.brown};
    text-decoration: underline;
    white-space: nowrap;
  }

  .icon-description-zone {
    width: 61px;
    padding: 7px 0 3px;
  }

  .pill-mode-info-box-second {
    height: 86px;
    width: 105px;
    margin-left: 16px;
    margin-right: 16px;
    @media (max-width: 374px) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .pill-mode-info-box-bottom-layer {
    display: flex;
    justify-content: center;
    height: 20px;
    width: 100%;
  }

  .pill-mode-info-box-bottom-layer-contents {
    display: flex;
    height: 100%;
  }
`;

const PillModeIntakeInfoBoxDom = (props: Props): JSX.Element => (
  <StyledInfoBox>
    <div className="pill-mode-info-box-second">
      <div className="pill-mode-info-box-top-layer">
        <div className="info-box-top-layer-text">{props.isAnyDrugPeriod ? '服薬日' : '休薬日'}</div>
      </div>
      <div className="pill-mode-info-box-middle-layer">
        <div className="pill-mode-info-box-middle-top-layer">
          <div className="pill-mode-pill-text-zone">
            <div className="pill-mode-pill-number-text">
              {props.isAnyDrugPeriod
                ? props.pillInfoBoxTexts.tabletNumber
                : props.pillInfoBoxTexts.drawalDate}
            </div>
            <div className="pill-mode-pill-string-text">
              {props.isAnyDrugPeriod ? '錠目' : '日目'}
            </div>
          </div>
        </div>
        <div className="pill-mode-info-box-middle-bottom-layer">
          <div className="pill-mode-info-box-count-down-zone">
            <div className="pill-mode-info-box-count-down-text-zone">
              <div className="count-down-text count-down-text-string-size whitespace-nowrap">
                {props.pillInfoBoxTexts.isActiveDrugPeriod ? '休薬(偽薬)まで' : '次のシートまで'}
              </div>
              <div className="count-down-text count-down-text-number-size">
                {props.pillInfoBoxTexts.isActiveDrugPeriod
                  ? props.pillInfoBoxTexts.remainedActiveDrugDate
                  : props.pillInfoBoxTexts.remainedSheetDate}
              </div>
              <div className="count-down-text count-down-text-string-size">日</div>
            </div>
          </div>
        </div>
      </div>
      <div className="pill-mode-info-box-bottom-layer">
        <div className="pill-mode-info-box-bottom-layer-contents">
          <div className="current-pill">
            <div className="info-box-bottom-layer-text info-box-bottom-layer-text-decoration">
              服薬中:&nbsp;
            </div>
            <div
              onClick={() =>
                props.dispatch({ type: 'handlePillDescriptionBottomSheet', payload: true })
              }
              className="info-box-bottom-layer-text info-box-bottom-layer-pill-text-decoration"
            >
              {clipText(props.pillName, 6, '...')}
            </div>
          </div>
        </div>
      </div>
    </div>
  </StyledInfoBox>
);

export const PillModeIntakeInfoBox = (props: ContainerProps): JSX.Element => {
  const {
    state: { femappUser },
    dispatch,
  } = useActionProvider();

  const [pillName, setPillName] = useState('');

  useEffect(() => {
    if (femappUser?.pill) {
      setPillName(femappUser.pill.name);
    }
  }, [femappUser]);

  const isAnyDrugPeriod =
    props.pillInfoBoxTexts.isActiveDrugPeriod || props.pillInfoBoxTexts.isPlaceboDrugPeriod;

  return <PillModeIntakeInfoBoxDom {...{ ...props, dispatch, isAnyDrugPeriod, pillName }} />;
};
