import { motion } from 'framer-motion';
import { NextPage } from 'next';
import dynamic, { DynamicOptions, Loader } from 'next/dynamic';
import { useState, useEffect } from 'react';
import { CalendarFloatButtons } from 'src/components/organisms/CalendarFloatButtons';
import { CalendarHeader } from 'src/components/organisms/CalendarHeader';
import { CalendarList } from 'src/components/organisms/CalendarList';
import { PageToastCalendar } from 'src/components/organisms/PageToastCalendar';
import { useActionProvider } from 'src/contexts/actions';
import { colors } from 'styles/styles';

type DynamicImport = DynamicOptions | Loader;

const importNoteBottomSheet: DynamicImport = import(
  'src/components/organisms/NoteBottomSheet'
).then((mod) => mod.NoteBottomSheet);

const NoteBottomSheet = dynamic(() => importNoteBottomSheet);

const importCalendarIconDescriptionBottomSheet: DynamicImport = import(
  'src/components/organisms/CalendarIconDescriptionBottomSheet'
).then((mod) => mod.CalendarIconDescriptionBottomSheet);

const CalendarIconDescriptionBottomSheet = dynamic(() => importCalendarIconDescriptionBottomSheet);

const importPillDescriptionBottomSheet = import(
  'src/components/organisms/PillDescriptionBottomSheet'
).then((mod) => mod.PillDescriptionBottomSheet);

const PillDescriptionBottomSheet = dynamic(() => importPillDescriptionBottomSheet);

const Index: NextPage = () => {
  const { state } = useActionProvider();
  const [isPillMode, setPillMode] = useState(false);
  const headerHeight = isPillMode ? 120 : 96;
  const headerYPadding = 16;
  const headerZoneHeight = headerHeight + headerYPadding * 2;

  useEffect(() => {
    if (state.femappUser) {
      setPillMode(state.femappUser.hasPillPlugin);
    }
  }, [state.femappUser]);

  return (
    <div
      style={{
        backgroundColor: colors.offWhite,
        height: 'auto',
        paddingBottom: state.openCalendarHeader ? 220 : 100,
        top: 0,
      }}
    >
      <motion.div
        animate={{ y: state.openCalendarHeader ? headerZoneHeight : 24 }}
        transition={{ duration: 0.3 }}
      >
        <CalendarList />
      </motion.div>
      <CalendarHeader {...{ isPillMode }} />
      <CalendarFloatButtons {...{ isPillMode }} />
      <CalendarIconDescriptionBottomSheet />
      <NoteBottomSheet />
      <PillDescriptionBottomSheet />
      <PageToastCalendar />
    </div>
  );
};

export default Index;
