import React, { forwardRef, ForwardedRef } from 'react';
import { ReactCalendarWrapper } from './ReactCalendarWrapper';
import { formatDateToStr } from 'src/lib/dateFormatter';
import { StyledCalendar } from 'styles/calendar';

export type CalendarProps = {
  activeStartDate: Date;
  currentCalendarIndex: number;
  idx: number;
};

export const Calendar = forwardRef(
  (props: CalendarProps, ref: ForwardedRef<HTMLDivElement>): JSX.Element => {
    return (
      <div key={formatDateToStr(props.activeStartDate)}>
        <StyledCalendar
          id={formatDateToStr(props.activeStartDate)}
          ref={props.idx == props.currentCalendarIndex ? ref : undefined}
        >
          <ReactCalendarWrapper activeStartDate={props.activeStartDate} />
        </StyledCalendar>
      </div>
    );
  },
);

Calendar.displayName = 'Calendar';
