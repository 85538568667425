import { useActionProvider } from 'src/contexts/actions';
import { useCurrentPillIntake } from 'src/hooks/useCurrentPillIntake';
import { cyclesToNextPeriodStartOn, getDateDiff, getToday } from 'src/lib/calendar';

type HeaderMenstrationInfo = {
  full?: { nextPeriodStartOn: string; remainedDates: string };
  hidden?: { monthText: string; dateText: string };
};

export const useHeaderMenstrationInfo = (): HeaderMenstrationInfo => {
  const {
    state: { femappUser, femappCycle },
  } = useActionProvider();
  const [currentPillIntake] = useCurrentPillIntake();

  let nextPeriodStartOn: Date;
  let remainedDates: number;

  if (femappUser?.hasPillPlugin) {
    // ピルモードの場合は、今日何錠目かの値を使って算出する
    const activeDrugSize = femappUser?.pill?.activeDrugSize;
    const tabletNumber = currentPillIntake?.tabletNumber;
    nextPeriodStartOn = getToday(); // 次の出血予定期間の開始日 (出血予定期間は、休薬偽薬機関の開始日+2 ~ 休薬偽薬期間の終了日)
    nextPeriodStartOn.setDate(nextPeriodStartOn.getDate() + activeDrugSize - tabletNumber + 1); // 休薬偽薬期間の開始日
    nextPeriodStartOn.setDate(nextPeriodStartOn.getDate() + 2); // 休薬偽薬期間の開始日 + 2
    remainedDates = activeDrugSize - tabletNumber + 1 + 2; // 次の出血予定日までの残り日数 (今日が出血予定期間の場合は、その次の出血予定期間の開始日を見る)
    if (tabletNumber > activeDrugSize + 2) {
      nextPeriodStartOn.setDate(nextPeriodStartOn.getDate() + 28);
      remainedDates += 28;
    }
  } else {
    // 生理モードの場合は、生理日を使ってに算出する
    nextPeriodStartOn = cyclesToNextPeriodStartOn(femappCycle?.cycles ?? []);
    if (!nextPeriodStartOn) return {};
    const today = getToday();
    remainedDates = getDateDiff(today, nextPeriodStartOn);
  }

  return {
    full: {
      nextPeriodStartOn: `${nextPeriodStartOn.getMonth() + 1}/${nextPeriodStartOn.getDate()}`,
      remainedDates: String(remainedDates),
    },
    hidden: {
      monthText: String(nextPeriodStartOn.getMonth() + 1),
      dateText: String(nextPeriodStartOn.getDate()),
    },
  };
};
