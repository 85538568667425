import { useState, useEffect, useMemo } from 'react';
import { useActionProvider } from 'src/contexts/actions';
import { getToday, countTabletNumber } from 'src/lib/calendar';
import { formatDateToStr, getDays } from 'src/lib/dateFormatter';

// 現在服薬している`予定`のシートにおけるパラメータ
export type CurrentSheetAttributes = {
  tabletNumber?: number; // 錠数
  nextPillIntakeStartOn?: Date; // 次のシートの開始日
  activeDrugEndOn?: Date; // 実薬最終日
  drawalDays?: number; // 休薬何日目か
  remainedActiveDrugDays?: number; // 休薬まで何日か
  remainedSheetDays?: number; // シート終了まで何日か
  isActiveDrugPeriod?: boolean; // 実薬期間かどうか
  isPlaceboDrugPeriod?: boolean; // 偽薬期間かどうか
};

type UseCurrentSheetAttributes = [
  CurrentSheetAttributes,
  {
    isLoading: boolean;
  },
];

export const useCurrentPillIntake = (): UseCurrentSheetAttributes => {
  const [currentSheetAttributes, setCurrentSheetAttributes] = useState<CurrentSheetAttributes>({});
  const [isLoading, setLoading] = useState(true);

  const {
    state: { femappUser },
  } = useActionProvider();

  const pillRelatedAttributes = useMemo((): CurrentSheetAttributes => {
    if (!femappUser?.pill || !femappUser?.sheetStartDate) {
      return {};
    }

    const pill = femappUser.pill;
    const today = getToday();
    const tabletNumber = countTabletNumber(femappUser?.sheetStartDate);

    const nextPillIntakeStartOn = new Date();
    nextPillIntakeStartOn.setDate(nextPillIntakeStartOn.getDate() + 28 - tabletNumber + 1);

    const activeDrugEndOn = new Date();
    activeDrugEndOn.setDate(activeDrugEndOn.getDate() + pill.activeDrugSize - tabletNumber);

    const drawalDays =
      tabletNumber > pill.activeDrugSize ? tabletNumber - pill.activeDrugSize : undefined;

    const remainedActiveDrugDays = getDays(activeDrugEndOn, today) + 1;

    const remainedSheetDays = getDays(nextPillIntakeStartOn, today);

    const isActiveDrugPeriod = formatDateToStr(today) <= formatDateToStr(activeDrugEndOn);

    const isPlaceboDrugPeriod =
      pill.placeboDrugSize > 0 &&
      formatDateToStr(activeDrugEndOn) < formatDateToStr(today) &&
      formatDateToStr(today) < formatDateToStr(nextPillIntakeStartOn);

    return {
      tabletNumber,
      nextPillIntakeStartOn,
      activeDrugEndOn,
      drawalDays,
      remainedActiveDrugDays,
      remainedSheetDays,
      isActiveDrugPeriod,
      isPlaceboDrugPeriod,
    };
  }, [femappUser?.pill, femappUser?.sheetStartDate]);

  useEffect(() => {
    if (pillRelatedAttributes) {
      setCurrentSheetAttributes({
        ...pillRelatedAttributes,
      });
      setLoading(false);
    }
  }, [pillRelatedAttributes]);

  return [currentSheetAttributes, { isLoading }];
};
