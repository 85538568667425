type CalendarDecoration =
  | 'PREDICTED_MENST'
  | 'DRAWAL'
  | 'TODAY'
  | 'TODAY_PREDICTED_MENST'
  | 'TODAY_DRAWAL'
  | 'TODAY_DRAWAL_PREDICTED_MENST'
  | 'DRAWAL_PREDICTED_MENST';

export const calendarDecorationInfo: {
  [key in CalendarDecoration]: { path: string; alt: string };
} = {
  TODAY_DRAWAL_PREDICTED_MENST: {
    path: 'calendar/today_drawal_predicted_menstruation_decoration.png',
    alt: 'today, drawal and prediceted menstruation',
  },
  TODAY_DRAWAL: { path: 'calendar/today_decoration.png', alt: 'today and drawal' },
  TODAY_PREDICTED_MENST: {
    path: 'calendar/today_predicted_menstruation_decoration.png',
    alt: 'today and prediceted menstruation',
  },
  DRAWAL_PREDICTED_MENST: {
    path: 'calendar/drawal_predicted_menstruation_decoration.png',
    alt: 'drawal and prediceted menstruation'
  },
  TODAY: { path: 'calendar/today_decoration.png', alt: 'today' },
  PREDICTED_MENST: {
    path: 'calendar/predicted_menstruation_decoration.png',
    alt: 'prediceted menstruation',
  },
  DRAWAL: { path: 'calendar/drawal_decoration.png', alt: 'drawal' },
};
