import React, { forwardRef, ForwardedRef, useEffect, useRef, useMemo } from 'react';
import { animateScroll } from 'react-scroll';
import styled from 'styled-components';
import { Calendar } from 'src/components/organisms/Calendar';
import { useActionProvider } from 'src/contexts/actions';
import { dateToCalendarIndex, getToday } from 'src/lib/calendar';
import { colors } from 'styles/styles';

const StyledCalendarList = styled.div`
  background-color: ${colors.offWhite};
  padding: 0 16px;
`;

type CalendarListDomProps = {
  calendarActiveStartDates: Date[];
  currentCalendarIndex: number;
};

export const CalendarList = (): JSX.Element => {
  const { state, dispatch } = useActionProvider();
  const initialScrollRef = useRef(null);

  const calendarActiveStartDates = useMemo((): Date[] => {
    const firstDateOfThisMonth = new Date();
    firstDateOfThisMonth.setDate(1);
    const monthDiffs = [...Array(19).keys()].map((v) => v - 12);
    return monthDiffs.map((diff) => {
      const targetDate = new Date(firstDateOfThisMonth.getTime());
      targetDate.setMonth(targetDate.getMonth() + diff);
      return targetDate;
    });
  }, []);

  const getCurrentCalendarIndex = (calendarActiveStartDates: Date[]): number | undefined => {
    const today = getToday();
    if (calendarActiveStartDates && calendarActiveStartDates.length == 0) return;
    return dateToCalendarIndex(today, calendarActiveStartDates);
  };

  const currentCalendarIndex = useMemo(
    () => getCurrentCalendarIndex(calendarActiveStartDates),
    [calendarActiveStartDates],
  );

  useEffect(() => {
    if (state.calendarScrollPosition) return animateScroll.scrollTo(state.calendarScrollPosition);

    if (currentCalendarIndex !== null && initialScrollRef.current?.getBoundingClientRect()) {
      const currentCalendarPosition = initialScrollRef.current.getBoundingClientRect().top;
      const pageTopMargin = 16;
      const initialScrollPosition = currentCalendarPosition - pageTopMargin;
      animateScroll.scrollTo(initialScrollPosition);
      dispatch({ type: 'setCalendarScrollPosition', payload: initialScrollPosition });
    }
  }, [currentCalendarIndex, dispatch, state.calendarScrollPosition, initialScrollRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  if (currentCalendarIndex === undefined) return <></>;

  const props: CalendarListDomProps = {
    calendarActiveStartDates,
    currentCalendarIndex,
  };
  return <CalendarListDom {...props} ref={initialScrollRef} />;
};

export const CalendarListDom = forwardRef(
  (props: CalendarListDomProps, ref: ForwardedRef<HTMLDivElement>): JSX.Element => {
    return (
      <StyledCalendarList>
        {props.calendarActiveStartDates.map((activeStartDate, idx) => (
          <Calendar
            key={idx}
            {...{
              activeStartDate,
              currentCalendarIndex: props.currentCalendarIndex,
              idx,
            }}
            ref={ref}
          />
        ))}
      </StyledCalendarList>
    );
  },
);

CalendarListDom.displayName = 'CalendarListDom';
