import { motion } from 'framer-motion';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { CalendarHeaderFullMenstruationMode } from 'src/components/organisms/CalendarHeaderFullMenstruationMode';
import { CalendarHeaderFullPillMode } from 'src/components/organisms/CalendarHeaderFullPillMode';
import { Dispatch, useActionProvider } from 'src/contexts/actions';
import { Cycle } from 'src/generated/graphql';
import { useCurrentPillIntake } from 'src/hooks/useCurrentPillIntake';
import { useHeaderMenstrationInfo } from 'src/hooks/useHeaderMenstrationInfo';
import { PillInfoBoxTexts } from 'src/lib/calendar';
import { colors } from 'styles/styles';

type MenstruationInfoBoxTexts = { nextPeriodStartOn: string; remainedDates: string };

type ContainerProps = {
  isPillMode: boolean;
  opening: boolean;
  cycles: Cycle[];
};

type Props = {
  isPillMode: boolean;
  menstInfoBoxTexts: MenstruationInfoBoxTexts;
  pillInfoBoxTexts: PillInfoBoxTexts;
  opening: boolean;
  dispatch: Dispatch;
};

const StyledCalendarHeaderFull = styled.div`
  .calendar-header-zone {
    padding: 0 0 0 8px;
    @media (max-width: 374px) {
      padding: 0 0.5px 0 7.5px;
    }
  }
  .calendar-header {
    display: flex;
    width: calc(100% - 8px);
    padding: 0 16px 0 0;
    border-radius: 8px 0 0 8px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.14);
    position: fixed;
    box-sizing: border-box;
    background-color: ${colors.cream};
  }

  .pill-mode-calendar-header-height {
    height: 120px;
  }

  .menst-mode-calendar-header-height {
    height: 96px;
  }

  .hide-button {
    width: 16px;
    box-sizing: border-box;
    padding-left: 7px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
  }
  .hide-vector {
    height: 12px;
    width: 6px;
  }
`;

const CalendarHeaderFullDom = (props: Props): JSX.Element => (
  <StyledCalendarHeaderFull>
    <div className="calendar-header-zone">
      <motion.div
        className={
          props.isPillMode
            ? 'calendar-header pill-mode-calendar-header-height'
            : 'calendar-header menst-mode-calendar-header-height'
        }
        animate={{
          opacity: props.opening ? 1 : 0,
          x: props.opening ? 0 : window.innerWidth,
        }}
        transition={{ duration: 0.3 }}
      >
        <div
          className="hide-button"
          onClick={() => props.dispatch({ type: 'handleCalendarHeader', payload: !props.opening })}
        >
          <img
            className="hide-vector"
            alt="vector to hide header"
            src="/vector_for_hiding_calendar_header.png"
          />
        </div>

        {props.isPillMode ? (
          <CalendarHeaderFullPillMode
            {...{
              menstInfoBoxTexts: props.menstInfoBoxTexts,
              pillInfoBoxTexts: props.pillInfoBoxTexts,
            }}
          />
        ) : (
          <CalendarHeaderFullMenstruationMode {...{ menstInfoBoxTexts: props.menstInfoBoxTexts }} />
        )}
      </motion.div>
    </div>
  </StyledCalendarHeaderFull>
);

export const CalendarHeaderFull = (props: ContainerProps): JSX.Element => {
  const { dispatch } = useActionProvider();
  const [currentPillIntake] = useCurrentPillIntake();
  const { full } = useHeaderMenstrationInfo();

  const pillInfoBoxTexts = useMemo(() => {
    return {
      tabletNumber: currentPillIntake?.tabletNumber?.toString() ?? '-',
      drawalDate: currentPillIntake?.drawalDays?.toString() ?? '-',
      remainedActiveDrugDate: currentPillIntake?.remainedActiveDrugDays?.toString() ?? '-',
      remainedSheetDate: currentPillIntake?.remainedSheetDays?.toString() ?? '-',
      isActiveDrugPeriod: currentPillIntake?.isActiveDrugPeriod ?? true,
      isPlaceboDrugPeriod: currentPillIntake?.isPlaceboDrugPeriod ?? false,
    };
  }, [currentPillIntake]);

  return (
    <CalendarHeaderFullDom
      {...{
        dispatch,
        isPillMode: props.isPillMode,
        menstInfoBoxTexts: {
          nextPeriodStartOn: full?.nextPeriodStartOn ?? '-/-',
          remainedDates: full?.remainedDates ?? '-',
        },
        pillInfoBoxTexts,
        opening: props.opening,
      }}
    />
  );
};
