import styled from 'styled-components';

export const StyledCalendar = styled.div`
  background-color: white;
  border-radius: 8px;
  margin-top: 16px;

  /* naviation */
  .react-calendar__navigation {
    pointer-events: none;
    display: flex;
  }
  .react-calendar__navigation__label {
    border-radius: 8px;
    background-color: white;
    border: none;
    padding: 18px 0 0 0;
  }
  .react-calendar__navigation__prev-button {
    display: none;
  }
  .react-calendar__navigation__prev2-button {
    display: none;
  }
  .react-calendar__navigation__next-button {
    display: none;
  }
  .react-calendar__navigation__next2-button {
    display: none;
  }
  .month-with-suffix {
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .month-number {
    font-size: 20px;
    font-family: 'Noto_Sans_JP_Bold', sans-serif;
  }
  .month-suffix {
    font-size: 12px;
    font-family: 'Noto_Sans_JP_Bold', sans-serif;
    align-self: flex-end;
    margin-left: 4px;
  }

  /* weekday and date */
  .react-calendar__month-view__weekdays {
    height: 14px;
    padding: 12px 0 0 0;
  }
  .react-calendar__month-view__weekdays__weekday {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    > abbr[title] {
      border-bottom: none !important;
      cursor: inherit !important;
      text-decoration: none !important;
    }
  }
  .react-calendar__month-view {
    padding: 0 20px 12px 20px;
  }
  .react-calendar__month-view__days {
    padding: -12px 0 0 0;
  }
  .react-calendar__tile {
    background-color: white;
    border: none;
    > abbr {
      display: none;
    }
    margin: 0;
  }
`;
