import React from 'react';
import styled from 'styled-components';
import { Dispatch } from 'src/contexts/actions';
import { colors } from 'styles/styles';

type Props = {
  dispatch: Dispatch;
  menstInfoBoxTexts: { nextPeriodStartOn: string; remainedDates: string };
};

const StyledInfoBox = styled.div`
  /* - info-box */

  .menst-mode-info-box-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: calc(100% - 185px);
  }

  .menst-mode-info-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 68px;
    width: 100%;
  }

  .pill-mode-info-box-first {
    height: 86px;
    width: 105px;
    @media (max-width: 374px) {
      width: 96px;
      padding-left: 8px;
    }
  }

  .pill-mode-info-box-top-layer {
    box-sizing: border-box;
    display: flex;
    padding-top: 1;
    height: 16px;
    width: 100%;
    justify-content: center;
  }

  /* -- top layer */

  .menst-mode-info-box-top-layer {
    display: flex;
    justify-content: center;
    height: 20px;
  }

  .info-box-top-layer-text {
    color: ${colors.charcoalGrey};
    font-weight: 500;
    font-size: 11px;
    line-height: 1;
  }

  /* -- middle layer */

  .menst-mode-info-box-middle-layer {
    display: flex;
    justify-content: center;
    height: 26px;
    width: 100%;
    margin: 2px 0;
  }

  .pill-mode-info-box-middle-layer {
    height: 50px;
    width: 100%;
  }

  .count-down-text {
    line-height: 1;
    color: white;
    font-family: 'Noto_Sans_CJK_JP', sans-serif;
  }

  .count-down-text-number-size {
    font-size: 12px;
  }
  .count-down-text-string-size {
    font-size: 10px;
  }

  .menst-mode-info-box-date-zone {
    display: flex;
    height: 26px;
    align-items: center;
  }
  .menst-mode-info-box-date-text {
    letter-spacing: -0.1rem;
    line-height: 1;
    color: ${colors.darkBlack};
    font-size: 26px;
    font-family: 'sofia-pro', sans-serif;
    font-weight: 600;
  }
  .menst-mode-info-box-count-down-zone-wrapper {
    margin-left: 6px;
    padding: 5px 0 1px;
  }
  .menst-mode-info-box-count-down-zone {
    display: flex;
    box-sizing: border-box;
    padding: 4px 6px 0;
    justify-content: center;
    height: 20px;
    border-radius: 11px;
    background-color: ${colors.forestGreen};
  }
  .menst-mode-info-box-count-down-text {
    display: flex;
    align-items: flex-end;
    height: 12px;
  }
  .pill-mode-info-box-middle-top-layer {
    display: flex;
    justify-content: center;
  }
  .pill-mode-date-text {
    line-height: 1;
    color: ${colors.darkBlack};
    font-size: 26px;
    font-family: 'sofia-pro', sans-serif;
    font-weight: 600;
  }
  .pill-mode-pill-text-zone {
    display: flex;
    align-items: flex-end;
  }
  .pill-mode-pill-number-text {
    line-height: 1;
    font-size: 26px;
    font-family: 'sofia-pro', sans-serif;
    font-weight: 700;
  }
  .pill-mode-pill-string-text {
    line-height: 1;
    font-size: 14px;
    font-family: 'Noto_Sans_CJK_JP', sans-serif;
    font-weight: 400;
  }
  .pill-mode-info-box-middle-bottom-layer {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 24px;
  }
  .pill-mode-info-box-count-down-zone {
    display: flex;
    box-sizing: border-box;
    padding: 4px 6px 0;
    justify-content: center;
    height: 20px;
    border-radius: 11px;
    background-color: ${colors.forestGreen};
  }
  .pill-mode-info-box-count-down-text-zone {
    display: flex;
    align-items: flex-end;
    height: 12px;
  }

  /* -- bottom layer */
  .current-pill {
    display: flex;
    padding: 7px 0 3px;
  }

  .info-box-bottom-layer-text {
    line-height: 1;
    font-size: 10px;
    font-family: 'Noto_Sans_CJK_JP', sans-serif;
  }

  .info-box-bottom-layer-text-decoration {
    color: ${colors.charcoalGrey};
    white-space: nowrap;
  }

  .info-box-bottom-layer-pill-text-decoration {
    color: ${colors.brown};
    text-decoration: underline;
    white-space: nowrap;
  }

  .icon-description-zone {
    width: 61px;
    padding: 7px 0 3px;
  }

  .pill-mode-info-box-bottom-layer {
    display: flex;
    justify-content: center;
    height: 20px;
    width: 100%;
  }

  .question-circle-zone {
    display: flex;
    align-items: flex-end;
    width: 16px;
  }

  .question-circle {
    height: 16px;
    width: 16px;
  }

  .pill-mode-info-box-bottom-layer-contents {
    display: flex;
    height: 100%;
  }

  .menst-mode-info-box-bottom-layer {
    display: flex;
    justify-content: center;
    height: 20px;
  }
`;

export const PillModeMenstruationInfoBox = (props: Props): JSX.Element => (
  <StyledInfoBox>
    <div className="pill-mode-info-box-first">
      {/* top --- */}
      <div className="pill-mode-info-box-top-layer">
        <div className="info-box-top-layer-text">生理予定日</div>
      </div>
      {/* --- top*/}

      {/* middle --- */}
      <div className="pill-mode-info-box-middle-layer">
        {/* middle top ---*/}
        <div className="pill-mode-info-box-middle-top-layer">
          <div className="pill-mode-date-text">{props.menstInfoBoxTexts.nextPeriodStartOn}</div>
        </div>
        {/* --- middle top */}

        {/* middle bottom ---*/}
        <div className="pill-mode-info-box-middle-bottom-layer">
          <div className="pill-mode-info-box-count-down-zone">
            <div className="pill-mode-info-box-count-down-text-zone">
              <div className="count-down-text count-down-text-string-size">あと</div>
              <div className="count-down-text count-down-text-number-size">
                {props.menstInfoBoxTexts.remainedDates}
              </div>
              <div className="count-down-text count-down-text-string-size">日</div>
            </div>
          </div>
        </div>
        {/* --- middle bottom */}
      </div>
      {/* --- middle */}

      {/* bottom --- */}
      <div className="pill-mode-info-box-bottom-layer">
        <div className="pill-mode-info-box-bottom-layer-contents">
          <div className="question-circle-zone">
            <img className="question-circle" alt="question mark" src="/question_circle.png" />
          </div>
          <div className="icon-description-zone">
            <div
              className="info-box-bottom-layer-text info-box-bottom-layer-text-decoration"
              onClick={() =>
                props.dispatch({ type: 'handleCalendarIconDescriptionBottomSheet', payload: true })
              }
            >
              アイコン説明
            </div>
          </div>
        </div>
      </div>
      {/* --- bottom */}
    </div>
  </StyledInfoBox>
);
